<template>
  <div class="content-box fit-content">
    <div class="register-inner">
      <el-button type="text" icon="el-icon-back" class="back-btn" @click="goToDestination">
        {{ $t('register.btn.back') }}
      </el-button>
      <div class="header">
        <h2 class="title">{{ title }}</h2>
        <slot name="header"></slot>
      </div>
      <StepLine v-if="stepLineData" :data="stepLineData" :stepNum="stepNum" />
      <div class="card">
        <div class="title-box">
          <h4 class="sub-title">{{ subTitle }}</h4>
          <p class="narrative" v-if="narrative" v-html="narrative"></p>
          <slot name="cardTitle"></slot>
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import StepLine from '@/components/common/StepLine.vue';
import { statusConfig } from '@/constants/status';
import { mapState } from 'vuex';

export default {
  name: 'DefaultRegister',
  components: { StepLine },
  props: {
    title: {
      type: String,
      default: ''
    },
    stepLineData: {
      type: Array
    },
    stepNum: {
      type: Number | String,
      default: 0
    },
    subTitle: {
      type: String,
      default: ''
    },
    narrative: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus'])
  },
  methods: {
    async goToDestination() {
      let destination = '/rebateWithdraw';
      switch (this.$route.name) {
        case 'financialInfo':
        case 'iDProof':
        case 'addressProof':
          if (this.ibtPoiAuditStatus === statusConfig.completed && this.ibtPoaAuditStatus === statusConfig.completed) {
            destination = null;
          }
          break;
        default:
          break;
      }

      if (destination) {
        await this.$router.push({ name: destination }).catch(error => {});
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.register-inner {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 16px;
}
.back-btn {
  color: $black;
  padding: 0;
  font-size: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: $black;
  margin-bottom: 10px;
}
.sub-title {
  font-size: 16px;
  font-weight: bold;
  color: $black;
  margin-bottom: 10px;
}
.card {
  border-radius: 16px;
  padding: 28px 21px;
  margin-top: 16px;
}
.title-box {
  margin-bottom: 24px;
}

.narrative {
  /deep/ a {
    color: $pug-blue;
  }
}
@media (min-width: 768px) {
  .back-btn {
    margin-top: 0;
    margin-bottom: 42px;
  }
  .title {
    font-size: 24px;
  }
  .sub-title {
    font-size: 18px;
  }

  .card {
    padding: 40px 80px;
  }
  .title-box {
    margin-bottom: 32px;
  }
}
</style>
